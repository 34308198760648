.footer-section-div{
    position: relative;
}
.footer-section-div section{
    padding-bottom: 60px !important;
}

#experience-priya{
    font-weight: 500 !important;
}

#footer-section{
    overflow: hidden;
}


.footer-Link-Main{
    display: flex;
    flex-direction: column;
    gap: 45px;
}
#footerLinks{
    display: flex;
    gap: 50px;
}
.footer-text{
    font-size: 17px !important;
    line-height: 18px !important;
    color: #575757 !important;
}

.footerLineSeparator{
    width: 90%;
    height: 2px;
    background-color: #494949;
}

.merchantsBg {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
}

.anchorTag {
    color: inherit !important;
  }

@media (max-width:992px){
    .footer-section-div section{
        padding-bottom: 30px !important;
    }
    #footerLinks{
        gap: 4px;
    }
    
}
@media (max-width:500px){
    #footer-copyright{
        font-size: 15px !important;
    }
    .footer-try-priya{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

}
