.PE-Main{
    background-color: white;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
    gap: 50px;
}

.PE-S1{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.PE-S1 div:nth-child(1){
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    color: black;
    text-align: center;
    width: 620px;
    margin: 0 auto;
}
.PE-S1 div:nth-child(2){
    font-size: 18px;
    line-height: 24px;
    color: #797979;
    text-align: center;
    width: 520px;
    margin: 0 auto;
}
.PE-S1 div:nth-child(3){
    display: flex;
    justify-content: center;
    width: 160px;
    margin: 0 auto;
    gap: 15px;
}
.PE-S1 div:nth-child(3) div:nth-child(1), .PE-S1 div:nth-child(3) div:nth-child(2){
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
}
.PE-S1 div:nth-child(3) div:nth-child(1) div:nth-child(1){
    width: 9px;
    height: 9px;
    background-color: #B3B3B3;
    border-radius: 50%;
}
.PE-S1 div:nth-child(3) div:nth-child(1) div:nth-child(2){
    color: #5F5F5F;
    font-size: 12px;
}
.PE-S1 div:nth-child(3) div:nth-child(2) div:nth-child(1){
    width: 9px;
    height: 9px;
    background-color: #00C4E9;
    border-radius: 50%;
}
.PE-S1 div:nth-child(3) div:nth-child(2) div:nth-child(2){
    color: #00C4E9;
    font-size: 12px;
}
.PE-S2{
    margin: 0 auto;
}
.priceEfficiencyGraph{
    /* width: 90%; */
}
.priceEfficiencyGraphMini{
    display: none;
}


@media (max-width: 770px){
    .PE-Main{
        padding: 60px 20px;
    }
    .PE-S1 div:nth-child(1){
        font-size: 24px;
        line-height: 30px;
        width: 380px;
    }
    .PE-S1 div:nth-child(2){
        font-size: 16px;
        line-height: 22px;
        width: 380px;
    }
    .priceEfficiencyGraph{
        display: none;
    }
    .priceEfficiencyGraphMini{
        display: block;
    }
}
@media (max-width: 430px){
    .PE-S1{
        gap: 20px;
    }
    .PE-S1 div:nth-child(1){
        font-size: 19px;
        line-height: 25px;
        width: 80vw;
    }
    .PE-S1 div:nth-child(2){
        font-size: 14px;
        line-height: 20px;
        width: 80vw;
    }
    .PE-S1 div:nth-child(3){
        margin-top: 5px;
    }
}