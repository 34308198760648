.primary-btn-div,
.secondary-btn-div {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Inter' !important;
    padding: 15px 28px;
    height: max-content;
    text-wrap: nowrap;
    border-radius: 30px;
    box-sizing: border-box;
    border-style: solid;
}

.secondary-btn-div {
    padding: 15px 36px;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

#mobile-hero-primary-btn {
    display: none;
}

@media (max-width: 768px) {

    .primary-btn-div,
    .secondary-btn-div {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding: 14px 24px;
    }

    .secondary-btn-div {
        padding: 14px 28px;
    }
}


@media (max-width:500px) {
    #desktop-hero-primary-btn {
        display: none;
    }

    #mobile-hero-primary-btn {
        display: block;
    }

    .primary-btn-div,
    .secondary-btn-div {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding-left: 30px;
        padding-right: 30px;
    }

    .secondary-btn-div {
        padding: 12px 24px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .mobile-primary-ml {
        margin-left: -10px;
    }

    .mobile-secondary-ml {
        margin-right: -10px;
    }
}

/* @media (max-width:400px) {
    .mobile-primary-ml {
        margin-left: -30px;
    }

    .mobile-secondary-ml {
        margin-right: -30px;
    }
} */

@media (max-width: 420px) {

    .primary-btn-div,
    .secondary-btn-div {
        font-size: 15px;
        line-height: 20px;
        padding: 11px 15px;
        padding-left: 22px;
        padding-right: 22px;
    }

    .secondary-btn-div {
        padding: 11px 17px;
        padding-left: 22px;
        padding-right: 22px;
    }

    .mobile-primary-ml {
        margin-left: -20px;
    }

    .mobile-secondary-ml {
        margin-right: -20px;
    }
}
@media (max-width: 365px) {

    .primary-btn-div,
    .secondary-btn-div {
        font-size: 14px;
        line-height: 18px;
        padding: 11px 12px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .secondary-btn-div {
        padding: 11px 12px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .mobile-primary-ml {
        margin-left: -10px;
    }

    .mobile-secondary-ml {
        margin-right: -10px;
    }
}