.pricing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 5%;
  /* padding-bottom: 8px; */
}

.text-content {
  text-align: center;
  width: 100%;
}

a{
  font-weight: inherit !important;
  font-family: inherit !important;
}
.unlock-revenue {
  color: #6b7280;
  font-size: 16px;
}

.main-text {
  margin-top: 20px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: #000;
}

.-description-text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
  color: #797979;
  padding-left: 8%;
  padding-right: 8%;
  padding: 0px;
}

.button-row {
  display: flex;
  gap: 1rem;
  margin-top: 8%;
}

@media (min-width: 640px) {
  .text-content {
    width: 60%;
  }
  .unlock-revenue {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .text-content {
    width: 60%;
  }
  .unlock-revenue {
    font-size: 20px;
  }
  .main-text {
    font-size: 26px;
    line-height: 34px;
  }
}

@media (min-width: 1024px) {
  .text-content {
    width: 50%;
  }
  .main-text {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (min-width: 1280px) {
  .text-content {
    width: 40%;
  }
}

@media (min-width: 1536px) {
  .text-content {
    width: 50%;
  }
  .-description-text {
    padding-left: 14%;
    padding-right: 14%;
  }
  .main-text {
    font-size: 40px;
    line-height: 48px;
  }
}

.get-started {
  border-radius: 30px;
  font-family: 'Inter' !important;
  font-weight: 700;
}
#book-a-demo{
  font-family: 'Inter' !important;
  font-weight: 700;
}

@media (min-width: 768px) {
  .get-started, #book-a-demo {
    font-size: 16px !important;
  }
}


@media (max-width: 768px){
  .pricing-container{
    padding: 60px 20px !important;
  }
  .get-started {
    padding: 12px 14px !important;
    padding-top: 12px !important ;
    padding-bottom: 12px !important;
  }
  #book-a-demo{
    width: 120px !important;
    min-width: 100px !important;
  }
}

@media (max-width: 400px) {
  .get-started, #book-a-demo {
    font-size: 10px !important;
  }
}
