#slideMainSection {
    margin-top: -100px;
    padding-top: 240px;
    background-color: black;
}

#main-heading-text {
    font-family: 'Inter' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
}

#slide-carousel .rec-arrow{
    margin-top: -70px;
}

.main-subtext {
    font-family: 'Inter' !important;
    font-size: 40px !important;
    font-weight: 500 !important;
    line-height: 50px !important;
    width: 600px !important;
}

#card-title {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
}

#card-description {
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    text-align: left;
}


@media (max-width:1199px) {
    #slideMainSection {
        margin-top: -100px !important;
        padding-top: 220px !important;
        background-color: black;
        /* padding-right: 0 */
    }
}


@media (max-width:768px) {
    #main-heading-text {
        font-size: 16px !important;
    }

    .main-subtext {
        font-size: 24px !important;
        line-height: 30px !important;
        width: 385px !important;
    }

    #card-title {
        font-family: 'Inter' !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        text-align: left;
    }
    #card-description {
        font-size: 14px !important;
        line-height: 19px !important;
    }
}
@media (max-width:768px) {
    #slide-carousel{
        width: 90vw;
        margin: 0 auto;
    }
}

@media (max-width:600px) {
    #slideMainSection {
        padding-top: 180px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    #slide-carousel .rec-item-wrapper{
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
    #slide-carousel .rec-arrow-right, #slide-carousel .rec-arrow-left{
        width: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
        min-height: 30px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
}
@media (max-width:500px) {
    #slide-carousel {
        width: calc(100vw - 30px);
        margin: 0 auto;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
    .image-style{
        height: 35vw !important;
    }
}

@media (max-width:440px) {
    .main-subtext {
        width: 85vw !important;
    }
    #slide-carousel .rec-item-wrapper{
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    #slide-carousel .rec-arrow-left{
        margin-left: -5px;
    }
    #slide-carousel .rec-arrow-right{
        margin-right: -5px;
    }
}