#main-heading-text {
    font-family: 'Inter' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
}

#main-subtext {
    font-family: 'Inter' !important;
    font-size: 40px !important;
    font-weight: 500 !important;
    line-height: 50px !important;
    width: 75% !important;
    min-width: 628px !important;
}

.tool-text {
    font-size: 13px !important;
    font-family: 'Inter' !important;
    font-weight: 500 !important;
}

.img-footer-text {
    font-family: 'Inter' !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    text-align: center;
}



@media (max-width:768px) {
    #main-heading-text {
        font-size: 16px !important;
    }

    #main-subtext {
        font-size: 24px !important;
        line-height: 30px !important;
        width: 70% !important;
        min-width: auto !important;
    }
}

@media (max-width:570px) {
    #main-subtext {
        width: 100% !important;
    }
}