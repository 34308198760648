.mobile-nav-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#hamburger{
    margin-right: 20px;
}

@media (max-width:992px) {
    .navbar {
        padding-top: 0px !important;
    }
}