.merchantsMain {
    display: flex;
    height: 630px;
    padding: 0 70px;
    position: relative;
    overflow-y: hidden;
}

.merchantsBg {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
}

.merchantsDiv1 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    font-weight: 500;
    width: 50%;
}

.merchantsDiv1Content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.merchantsDiv1Content div:nth-child(1) {
    font-size: 40px;
    line-height: 50px;
    font-family: 'Inter' !important;
    font-weight: 500;
    color: white;
    width: 85%;
}

.merchantsDiv1Content div:nth-child(2) {
    font-size: 24px;
    line-height: 36px;
    font-family: 'Inter' !important;
    font-weight: 500;
    color: #999999;
    width: 85%;
}

.trustedImg {
    width: 358px;
    height: 68px;
}

.merchantsDiv2 {
    display: flex;
    gap: 10px;
}
.custom-slider{
    display: none;
}
#customSliderMain{
    display: none !important;
}

.merchantsDiv2Content {
    display: flex;
    flex-direction: column;
}

/* .t1 {
    height: 200px;
}

.t2 {
    height: 289px;
}

.t3 {
    height: 260px;
}

.t4 {
    height: 289px;
}

.t5 {
    height: 289px;
}

.t6 {
    height: 180px;
} */

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.swiper-button-prev {
  left: 10px;
  display: none;
}

.swiper-button-next {
  right: 10px;
  display: none;
}

.swiper-button-prev img,
.swiper-button-next img {
  width: 40px;
  height: 40px;

}

@media (max-width:1280px) {
    .merchantsMain {
        padding: 0 80px;
        height: 600px;
    }

    .merchantsDiv1Content div:nth-child(1) {
        font-size: 30px;
        line-height: 40px;
        width: 85%;
    }

    .merchantsDiv1Content div:nth-child(2) {
        font-size: 20px;
        line-height: 26px;
    }

    .trustedImg {
        width: 300px;
        height: 60px;
    }

    .t1 {
        height: 160px;
    }

    .t2 {
        height: 249px;
    }

    .t3 {
        height: 220px;
    }

    .t4 {
        height: 249px;
    }

    .t5 {
        height: 249px;
    }

    .t6 {
        height: 140px;
    }
}

@media (max-width:1200px) {
    .merchantsMain {
        padding: 0 20px;
    }
}

@media (max-width:930px) {
    .merchantsMain {
        min-height: 500px;
    }

    .merchantsDiv2 {
        width: 50%;
    }

    .trustedImg {
        width: 280px;
        height: 60px;
    }

    .t1 {
        height: 140px;
    }

    .t2 {
        height: 229px;
    }

    .t3 {
        height: 200px;
    }

    .t4 {
        height: 229px;
    }

    .t5 {
        height: 229px;
    }

    .t6 {
        height: 120px;
    }
}

@media (max-width:770px) {
    .merchantsMain{
        flex-direction: column;
        gap: 50px;
        padding: 60px 20px;
        min-height: 650px;
    }


    .merchantsDiv1, .merchantsDiv2{
        width: 100%;
    }
    .trustedImg {
        width: 270px;
        height: 54px;
    }
    
    .merchantsDiv1Content div:nth-child(1) {
        font-size: 24px;
        line-height: 30px;
        width: 100%;
    }

    .merchantsDiv1Content div:nth-child(2) {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
    }

    .custom-slider{
        display: block;
    }
    #customSliderMain{
        display: block !important;
    }
    .merchantsDiv2{
        display: none;
    }
    .merchantsDiv2MobileContent{
        margin: 0 auto;
    }

    .t1, .t2, .t3, .t4, .t5, .t6{
        height: 255px;
        width: 70vw;
        border-radius: 20px;
        overflow: hidden;
        object-fit: fill;
    }
}

@media (max-width:500px){
    .merchantsMain{
        min-height: 600px;
    }
    .t1, .t2, .t3, .t4, .t5, .t6{
        height: 185px;
        /* width: 80vw; */
    }
}
@media (max-width:400px){
    .t1, .t2, .t3, .t4, .t5, .t6{
        height: 185px;
    }
}
@media (max-width:360px){
    .merchantsMain{
        min-height: 660px;
    }
}


.custom-swiper-class::-webkit-scrollbar-thumb{
    background: transparent;
}
.custom-swiper-class::-webkit-scrollbar{
    background: transparent;
}

.trustArrowsDiv{
    display: flex;
    width: 38px;
    height: 100%;
    align-items: center;
}
.trustArrowsDiv img{
    cursor: pointer;
}
.custom-slider-div{
    overflow: hidden;
    border-radius: 20px;
}