.gradient-text {
  font-size: 64px;
  font-weight: 500;
  /* font-family: 'Inter' !important; */
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #00c4e9 0%, #00f795 100%);
}

.subtitle {
  font-size: 48px;
  line-height: 55px;
  font-weight: 400;
  color: white;
  text-align: center;
  /* font-family: 'Inter' !important; */
}

.responsive-paragraph {
  color: #999999;
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  /* font-family: 'Inter' !important; */
  font-weight: 300;
  max-width: 288px;
  margin: 0 auto;
}

.hero-first-section {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  /* font-family: 'CircularSpotifyTxT-Light' !important; */
  font-family: 'Inter' !important;
}

.hero-first-section-btn-div {
  margin-top: 25px;
}

#banner-bottom-get-started {
  font-family: 'Inter' !important;
  font-weight: 700 !important;
}

.ai-analysis-title {
  line-height: 48px;
}

.description-text {
  font-size: 18px;
  line-height: 22px;
}

#heroBottomBanner {
  margin-bottom: -280px;
}

.shopify-logo {
  width: 155px;
}

#hero-book-a-demo {
  font-size: 16px !important;
  font-family: 'Inter' !important;
  font-weight: 700 !important;
}

#hero-get-started {
  font-size: 16px !important;
  font-family: 'Inter' !important;
  font-weight: 700 !important;
}

.custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.anchorTag {
  color: inherit !important;
  font-weight: inherit !important;
}


@media (max-width:1280px) {
  .hero-first-section {
    gap: 15px;
  }
}

@media (max-width:1100px) {

  #heroBottomBanner {
    margin-bottom: -180px;
  }

  .ai-analysis-title {
    line-height: 37px;
  }
}

@media (max-width:768px) {
  #heroBottomBanner {
    margin-bottom: -120px;
  }

  .gradient-text {
    font-size: 44px !important;
    line-height: 48px !important;
  }

  .subtitle {
    font-size: 38px !important;
    line-height: 46px !important;
  }

  .responsive-paragraph {
    margin-top: 5px;
    font-size: 24px !important;
    line-height: 32px !important;
    font-weight: 500 !important;
  }

  .shopify-logo {
    width: 130px;
  }

  .trusted-hero-text {
    font-size: 10px !important;
  }

  .star-icon {
    width: 0.9rem !important;
    height: 0.9rem !important;
  }

  #hero-book-a-demo {
    width: 120px !important;
    min-width: 100px !important;
    font-size: 11px !important;
    line-height: 15px !important;
    padding: 12px 12px !important;
  }

  #hero-get-started {
    padding: 12px 12px !important;
    font-size: 11px !important;
    line-height: 15px !important;
  }

  .ai-analysis-title {
    line-height: 30px;
  }

  .banner-mobile {
    min-height: 60vh;
  }

}

@media (max-width: 640px) {
  .avatars {
    margin-top: 20px !important;
  }
}

@media (max-width:500px) {
  .gradient-text {
    font-size: 40px !important;
    line-height: 48px !important;
  }

  .subtitle {
    font-size: 38px !important;
    line-height: 46px !important;
  }

  .hero-first-section {
    margin-top: -10px;
    gap: 12px;
  }

  .shopify-logo {
    width: 150px;
  }

  .trusted-hero-text {
    font-size: 13px !important;
  }

  .star-icon {
    width: 1rem !important;
    height: 1rem !important;
  }

  .ai-analysis-title {
    line-height: 30px;
  }

  .banner-mobile {
    min-height: 60vh;
  }

  .hero-first-section-btn-div {
    margin-top: 20px;
  }

  .avatar {
    height: 3.5rem !important;
    /* h-10 */
    width: 3.5rem !important;
  }


}

@media (max-width:430px) {


  .shopify-logo {
    width: 140px;
  }

  .trusted-hero-text {
    font-size: 12px !important;
  }

  .star-icon {
    width: 1rem !important;
    height: 1rem !important;
  }

  .ai-analysis-title {
    line-height: 30px;
  }

  .banner-mobile {
    min-height: 60vh;
  }

  .hero-first-section-btn-div {
    margin-top: 20px;
  }

  .avatar {
    height: 3.5rem !important;
    /* h-10 */
    width: 3.5rem !important;
  }

}

@media (max-width:400px) {


  #heroBottomBanner {
    margin-bottom: -80px;
  }

  .hero-first-section {
    gap: 10px;
  }

  .shopify-logo {
    width: 120px;
  }

  .custom-content {
    gap: 0px !important;
  }

  .trusted-hero-text {
    text-align: start;
  }

  .custom-stars {
    text-align: start !important;
    line-height: 12px !important;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

@media (max-width:360px) {

  .shopify-logo {
    width: 100px;
  }

  .hero-first-col {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .avatar {
    height: 3rem !important;
    /* h-10 */
    width: 3rem !important;
  }
}

.BottomBanner {
  /* text-shadow: 0px 2px 6px 0px #00000026; */
  box-shadow: none !important;
}

.get-started {
  font-size: 13px;
  border-radius: 30px;
}

@media (min-width: 768px) {
  .get-started {
    font-size: 16px;
  }
}


@media (min-width: 1280px) {
  .responsive-paragraph {
    margin-top: -6px !important;
  }
}



@media (min-width: 640px) {
  .responsive-paragraph {
    max-width: 448px;
  }
}


@media (min-width: 1024px) {
  .responsive-paragraph {
    max-width: 576px;
  }
}

@media (min-width: 1280px) {
  .responsive-paragraph {
    max-width: 672px;
  }
}


.custom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: black; */
}




@media (min-width: 640px) {

  /* sm:flex-row */
  .custom-container {
    flex-direction: row;
  }

}


.vertical-divider {
  margin: 0 16px;
  /* mx-4 */
  height: 2.5rem;
  /* h-10 */
  border-left: 1px solid #4b5563;
  /* border-gray-600 */
}


.stars {
  display: flex;
  justify-content: start;
}

.star-icon {
  height: 1rem;
  /* h-4 */
  width: 1rem;
  /* w-4 */
  fill: #facc15;
  /* text-yellow-400 */
}

.text {
  /* margin-left: 8px;  */
  font-size: 0.875rem;
  /* text-sm */
  color: #9ca3af;
  /* text-gray-400 */
}

.trusted-text {
  margin-left: 8px;
  /* ml-2 */
  margin-top: 8px;
  /* mt-2 */
  font-size: 1.25rem;
  /* text-xl */
  color: #9ca3af;
  /* text-gray-400 */
}

.avatars {
  display: flex;
  margin-top: 16px;
  /* mt-4 */
}

.avatar {
  height: 3rem;
  /* h-10 */
  width: 3rem;
  /* w-10 */
  border-radius: 9999px;
  /* rounded-full */
}

.avatar-margin {
  margin-left: -12px;
  /* -ml-3 */
}

@media (min-width: 640px) {
  .sm-visible {
    display: block;
  }

  .sm-hidden {
    display: none;
  }
}

.custom-dashboard-image {
  border-radius: 15px;
  /* rounded-[15px] */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  /* drop-shadow-lg */
}

@media (min-width: 640px) {
  .custom-dashboard-image {
    padding-left: 2.5rem;
    /* equivalent to sm:px-10 */
    padding-right: 2.5rem;
    /* equivalent to sm:px-10 */
  }
}

@media (min-width: 768px) {
  .custom-dashboard-image {
    /* height: 600px; */
    /* md:h-[900px] */
    width: 1600px;
    /* md:w-[1600px] */
  }
}

@media (min-width: 1024px) {
  .custom-dashboard-image {
    padding-left: 5rem;
    /* equivalent to lg:px-20 */
    padding-right: 5rem;
    /* equivalent to lg:px-20 */
    /* height: 700px; */
  }
}

.container {
  background-color: black;
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.text-container {
  /* margin-top: 28px; */
}

.dynamic-text {
  color: #7c7c7c;
  font-size: 30px;
  line-height: 42px;
  font-weight: 600;
  margin: 0 auto;
  max-width: 45rem;
}
.shopify-video-div{
  height: 40vw;
}
.video-volume-btn-icon{
  font-size: 24px;
}
.video-volume-btn{
  right: 60px;
}

@media (max-width:1280px) {
  .video-volume-btn-icon{
    font-size: 20px;
  }
}

@media (max-width:1024px) {
  .dynamic-text{
    font-size: 24px;
    line-height: 36px;
    max-width: 100%;
  }
  /* .video-volume-btn{
    right: 4%;
  } */
  .video-volume-btn{
    right: 50px;
  }
  
}
@media (max-width:768px) {
  .dynamic-text{
    font-size: 22px;
    line-height: 34px;
  }
  .video-volume-btn-icon{
    font-size: 18px;
  }
}
@media (max-width:450px) {
  .shopify-video-div{
    height: 45vw;
  }
}


.stats-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
}

@media (min-width: 640px) {
  .stats-container {
    flex-direction: row;
    justify-content: space-around;
  }
}

.conversion-stat,
.revenue-stat,
.order-value-stat {
  color: white;
}

.conversion-value,
.revenue-value,
.order-value {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

@media (min-width: 768px) {

  .conversion-value,
  .revenue-value,
  .order-value {
    font-size: 4.5rem;
  }
}

.arrow-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.conversion-description,
.revenue-description,
.order-description {
  color: #b0b0b0;
  font-size: 0.875rem;
}

@media (min-width: 768px) {

  .conversion-description,
  .revenue-description,
  .order-description {
    font-size: 1rem;
  }
}

.ai-analysis-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
}

.ai-analysis-content {
  text-align: center;
  font-family: 'Inter' !important;
}

@media (min-width: 640px) {
  .ai-analysis-content {
    padding-top: 64px;
    padding-bottom: 40px;
  }
}

.ai-analysis-title,
.ai-analysis-description {
  display: flex;
  justify-content: center;
}

.title-text {
  color: white;
  font-size: 24px;
  font-weight: 500;
  max-width: 20rem;
  text-align: center;
}

@media (min-width: 640px) {
  .title-text {
    font-size: 1.25rem;
    max-width: 24rem;
  }
}

@media (min-width: 768px) {
  .title-text {
    font-size: 30px;
  }
}

@media (min-width: 1024px) {
  .title-text {
    font-size: 40px;
    max-width: 38rem;
  }
}

.description-text {
  color: #999999;
  margin-top: 20px;
  font-weight: 500;
  max-width: 18rem;
  margin-bottom: 20px;
  text-align: center;
}

@media (min-width: 640px) {
  .description-text {
    max-width: 20rem;
  }
}

@media (min-width: 768px) {
  .description-text {
    max-width: 24rem;
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1024px) {
  .description-text {
    max-width: 36rem;
  }
}

.ai-analysis-button {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}

.get-started-btn {
  background-color: white;
}