.button-carousel-container {
  margin-top: 2.5rem;
  /* mt-10 */
}

.button-carousel-wrapper {
  margin-bottom: 1rem;
  /* mb-4 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-grid {
  display: none;
}

.mobile-button-carousel .rec-carousel-item .rec-item-wrapper {
  width: max-content;
}

.mobile-button {
  width: 524px;
}

.arrow-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.arrow-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ArrowsDiv {
  display: flex;
  align-items: center;
  gap: 20px;
}


.button {
  border-radius: 9999px;
  /* rounded-full */
  padding: 0.5rem 1rem;
  /* px-4 py-2 */
  font-size: 0.75rem;
  /* text-xs */
  font-weight: 500;
  font-family: 'Inter' !important;
  outline: none;
  /* focus:outline-none */
}

.button-active {
  background-color: black;
  /* bg-black */
  color: white;
  /* text-white */
}

.button-inactive {
  border: 1px solid #d1d5db;
  /* border-gray-400 */
  background-color: white;
  /* bg-white */
  color: black;
  /* text-black */
}

.disabled-arrow {
  opacity: 0.5;
  pointer-events: none;
}

@media (min-width: 640px) {
  .button {
    padding: 0.75rem 1.5rem;
    /* sm:px-6 sm:py-3 */
    font-size: 0.875rem;
    /* sm:text-sm */
  }
}

@media (min-width: 768px) {
  .button {
    padding: 1rem 2rem;
    /* md:px-8 md:py-4 */
    font-size: 1rem;
    /* md:text-base */
  }

  .button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    /* gap-4 */
  }

  .mobile-button {
    display: none;
  }

  .arrow-container {
    display: none;
  }
}

@media (min-width: 1024px) {
  .button-grid {
    display: flex;
    gap: 1rem;
    /* lg:gap-4 */
  }
}

@media (max-width: 768px) {
  .button {
    font-size: 16px !important;
  }

  .button-carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 550px) {
  .mobile-button {
    width: 95vw;
  }
}

@media (max-width: 490px) {
  .button {
    font-size: 15px !important;
  }

  .mobile-button {
    width: 99vw;
  }
}

@media (max-width: 450px) {
  .button {
    font-size: 14px !important;
  }
}

@media (max-width: 430px) {
  .mobile-button {
    width: 100%;
  }
  .button {
    font-size: 13px !important;
  }
  .rec-slider-container {
    margin: 0px !important;
  }
}
@media (max-width: 400px) {
  .button {
    font-size: 12px !important;
    font-weight: 600;
  }
  .rec-slider-container {
    margin: 0px !important;
  }
}
@media (max-width: 380px) {
  .button {
    font-size: 11px !important;
  }
  .rec-slider-container {
    margin: 0px !important;
  }
}

@media (max-width: 350px) {
  .mobile-button {
    width: 100%;
  }

  .button {
    font-size: 10px !important;
  }
}