.how-it-work {
  padding-top: 300px;
}

#get-started-for-free {
  font-family: 'Inter' !important;
  font-weight: 700 !important;
}

#book-a-demo {
  font-family: 'Inter' !important;
  font-weight: 700 !important;
}

#modishladyText {
  font-weight: 500 !important;
  font-family: 'Inter' !important;
}

#workBtnsDiv{
  padding-right: 0px !important;
}

#case-studies-title {
  font-family: 'Inter' !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 25px !important;
}

#modishladyBottomText {
  font-weight: 400 !important;
  font-family: 'Inter' !important;
}

#modishladyBottomText span {
  font-weight: 700 !important;
}

.anchorTag {
  color: inherit !important;
}


.points-main-div-first {
  max-width: 70% !important;
  min-width: 470px !important;
}

.case-section {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.work-btn-div {
  margin-top: 40px;
}

#workContent {
  display: flex;
  flex-direction: row !important;
  align-items: start;
}

#mobile-work-ps-button{
  display: none !important;
}

@media (max-width:1340px) {

  /* #work-btm-img-div{
    padding-bottom: 100px;
  } */
  .priyaImage {
    height: 400px;
  }
}

@media (max-width:1200px) {
  #case-section-content {
    padding-left: 40px;
  }
  #desktop-work-ps-button{
    display: none !important;
  }
  #mobile-work-ps-button{
    display: flex !important;
    gap: 30px !important;
  }
}

@media (max-width:1100px) {
  .how-it-work {
    padding-top: 220px;
  }

  .points-main-div-first {
    max-width: 100% !important;
    min-width: auto !important;
  }

  .priyaImage {
    height: 380px;
  }
}

@media (max-width:1024px) {
  #workContent {
    padding-top: 20px !important;
  }

  .points-main-div-first {
    max-width: 70% !important;
  }
  #case-section-content:nth-child(1) {
    padding-left: 0px;
  }
  #case-section-content:nth-child(2) {
    padding-left: 20px;
  }

}

@media (max-width:930px) {
  .priyaImage {
    width: 45vw !important;
    /* height: 350px; */
  }
}

@media (max-width:768px) {
  .how-it-work {
    padding-top: 140px;
  }

  #book-a-demo {
    width: 120px !important;
    min-width: 100px !important;
    font-size: 11px !important;
    /* padding-top: 10px !important;
    padding-bottom: 10px !important; */
  }

  #get-started-for-free {
    font-size: 11px !important;
    /* padding-top: 10px !important;
    padding-bottom: 10px !important; */
  }

  #modishladyText {
    font-size: 24px !important;
    line-height: 30px !important;
  }

  #modishladyBottomText {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  #case-section-content:nth-child(1) {
    padding-left: 20px;
  }

  #caseStudySection {
    padding: 0px 0px !important;
  }

  #caseStudyTryBtn {
    padding: 10px 16px !important;
    font-size: 13px !important;
  }

  .image-comparison-section {
    margin-top: 3rem !important;
  }

  .priyaImage {
    width: 80vw !important;
    /* height: 65vh; */
  }

  .priyaImage:last-child {
    margin-left: 0px !important;
    margin-top: -10px;
  }

  #work-btm-img-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price-comparison-container {
    padding-bottom: 400px !important;
    margin-bottom: -40px;
  }

  .background-section {
    padding: 0 !important;
  }

  .points-main-div-first {
    max-width: 95% !important;
    min-width: auto !important;
  }
  #case-studies-title {
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
  }
  
}


@media (max-width:640px) {
  .price-comparison-container {
    margin-bottom: -50px;
  }
}

@media (max-width:625px) {
  .price-comparison-container {
    margin-bottom: -75px;
  }
}

@media (max-width:600px) {
  .price-comparison-container {
    padding-bottom: 360px !important;
  }
}

@media (max-width:550px) {
  .price-comparison-container {
    padding-bottom: 310px !important;
  }

  .points-main-div-first {
    max-width: 100% !important;
    width: 100%;
    min-width: auto !important;
  }
}

@media (max-width:530px) {
  .price-comparison-container {
    padding-bottom: 290px !important;
  }
}

@media (max-width:515px) {
  .price-comparison-container {
    padding-bottom: 270px !important;
  }
}

@media (max-width:500px) {
  .price-comparison-container {
    padding-bottom: 250px !important;
  }

  .case-section {
    padding-bottom: 30px !important;
  }
  #mobile-work-ps-button{
    gap: 8px !important;
    margin-left: -15px !important;
  }

}

@media (max-width:470px) {
  .price-comparison-container {
    padding-bottom: 230px !important;
  }
}

@media (max-width:450px) {
  .price-comparison-container {
    padding-bottom: 220px !important;
  }

  .points-main-div {
    padding-left: 0px !important;

  }
}

@media (max-width:430px) {
  .price-comparison-container {
    padding-bottom: 200px !important;
  }

}

@media (max-width:415px) {
  .price-comparison-container {
    padding-bottom: 180px !important;
  }

}

@media (max-width:400px) {
  .how-it-work {
    padding-top: 90px;
  }

  .price-comparison-container {
    padding-bottom: 150px !important;
  }
}

@media (max-width:380px) {
  .price-comparison-container {
    padding-bottom: 130px !important;
  }
}

@media (max-width:370px) {
  .price-comparison-container {
    padding-bottom: 120px !important;
  }
}

@media (max-width:350px) {
  .price-comparison-container {
    padding-bottom: 110px !important;
  }
}

@media (max-width:330px) {
  .price-comparison-container {
    padding-bottom: 90px !important;
  }
}

@media (max-width:315px) {
  .price-comparison-container {
    padding-bottom: 70px !important;
  }
}

@media (max-width:305px) {
  .price-comparison-container {
    padding-bottom: 50px !important;
  }
}

@media (max-width:290px) {
  .price-comparison-container {
    padding-bottom: 40px !important;
  }
}

.how-it-works-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works-title {
  font-size: 18px;
  font-family: 'Inter' !important;
  font-weight: 500;
  color: #7c7c7c;
}

.how-it-works-description {
  margin-top: 20px;
  width: 90%;
  text-align: center;
  font-size: 24px;
  font-family: 'Inter' !important;
  line-height: 30px;
  font-weight: 500;
  color: black;
}

@media (min-width: 640px) {
  .how-it-works-title {
    font-size: 20px;
  }

  .how-it-works-description {
    width: 70%;
    font-size: 30px;
    line-height: 38px;
  }
}

@media (min-width: 768px) {
  .how-it-works-title {
    font-size: 20px;
  }

  .how-it-works-description {
    width: 75%;
    font-size: 34px;
    line-height: 44px;
  }
}

@media (min-width: 1024px) {
  .how-it-works-title {
    font-size: 20px;
  }

  .how-it-works-description {
    width: 70%;
    font-size: 38px;
  }
}

@media (min-width: 1280px) {
  .how-it-works-description {
    width: 62%;
    font-size: 40px;
    line-height: 50px;
  }
}

@media (min-width: 1536px) {
  .how-it-works-description {
    width: 62%;
    font-size: 40px;
    line-height: 50px;
  }
}

/* Grid container */
.grid-container {
  display: grid;
  padding-left: 1rem;
  /* px-4 */
  padding-right: 1rem;
}

@media (min-width: 1024px) {
  .grid-container {
    margin-left: 2%;
    margin-right: 2%;
    /* lg:mx-[2%] */
    grid-template-columns: 1fr 1fr;
    /* lg:grid-cols-2 */
  }
}

@media (min-width: 1536px) {
  .grid-container {
    margin-left: 5%;
    margin-right: 5%;
    /* 2xl:mx-[5%] */
  }
}

/* Image container */
.image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 0.5rem;
  /* py-10 */
  padding-bottom: 2rem;
}

.image-wrapper {
  display: flex;
  max-width: 800px;
  flex-direction: column;
  gap: 0.5rem;
  /* gap-2 */
}

@media (min-width: 768px) {
  .image-wrapper {
    gap: 1rem;
    /* md:gap-4 */
  }
}

.dashboard-image {
  width: 100%;
}

@media (min-width: 768px) {
  .dashboard-image {
    height: 552px;
    width: 721px;
    /* md:h-[552px] md:w-[721px] */
  }
}

/* Text container */
.text-container {
  margin-bottom: 1.25rem;
  /* mb-5 */
  padding-left: 2%;
  /* md:px-[2%] */
  padding-right: 2%;
}

@media (min-width: 1024px) {
  .text-container {
    padding-top: 2.5rem;
    /* lg:pt-10 */
    padding-left: 5rem;
    /* xl:px-20 */
    padding-right: 5rem;
  }
}

@media (min-width: 1536px) {
  .text-container {
    padding-left: 5%;
    /* 2xl:px-[5%] */
    padding-right: 5%;
    padding-top: 4rem;
    /* xl:pt-16 */
  }
}

/* Description heading */
.description-heading {
  font-size: 18px;
  /* text-[18px] */
  margin-bottom: 0.5rem;
  /* mb-2 */
  font-family: 'Inter' !important;
  font-weight: 500;
  /* font-medium */
  color: black;
}

@media (min-width: 768px) {
  .description-heading {
    font-size: 18px;
    /* md:text-[19px] */
  }
}

@media (min-width: 1024px) {
  .description-heading {
    font-size: 20px;
    /* lg:text-[20px] */
  }
}

/* Points list */
.points-list {
  max-width: 48rem;
  /* max-w-3xl */
  list-style: none;
  margin-top: 35px !important;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.list-item {
  display: flex;
  align-items: center;
  font-weight: 500;
  /* font-medium */
  font-family: 'Inter' !important;
  color: black;
  font-size: 16px !important;
  line-height: 22px !important;
  /* text-[16px] */
}


@media (min-width: 1024px) {
  .list-item {
    font-size: 18px !important;
    line-height: 24px !important;
    /* lg:text-[18px] */
  }
}

/* Check icon */
.check-icon {
  margin-right: 0.5rem;
  align-self: flex-start;
  height: 23px;
  width: 26px;
}

/* Button wrapper */
.button-wrapper {
  display: flex;
  gap: 1rem;
  /* space-x-4 */
}

@media (min-width: 640px) {
  .button-wrapper {
    flex-direction: row;
    /* sm:flex-row */
    gap: 0.5rem;
    /* sm:space-x-2 */
  }
}

.case-study-container {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 1024px) {
  .case-study-container {
    flex-direction: row;
  }
}

/* Text section */
.case-study-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .case-study-content {
    flex-direction: row;
  }
}

.case-study-text {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.text-block {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 1024px) {
  .text-block {
    width: 80%;
    margin-left: 4rem;
  }
}

/* Case studies header */
.case-studies-header {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.case-studies-header-large {
  display: none;
}

@media (min-width: 1024px) {
  .case-studies-header-large {
    display: block;
  }
}

.case-studies-header-small {
  display: block;
}

@media (min-width: 1024px) {
  .case-studies-header-small {
    display: none;
  }
}

/* Case study title */
.case-study-title {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1024px) {
  .case-study-title {
    font-size: 2rem;
  }
}

@media (min-width: 1536px) {
  .case-study-title {
    font-size: 2.25rem;
  }
}

/* Case study subtitle */
.case-study-subtitle {
  color: #737373;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1024px) {
  .case-study-subtitle {
    font-size: 1.5rem;
  }
}

.highlighted-text {
  color: white;
}

/* Case study points */
.case-study-points {
  list-style: none;
  padding-left: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 48rem;
  color: #999999;
  font-size: 16px;
  line-height: 22px;
  text-align: start;
  font-family: 'Inter' !important;
  font-weight: 500 !important;
}

@media (min-width: 992px) {
  .case-study-points {
    font-size: 18px;
    line-height: 22px;
  }
}

.case-study-point {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.check-icon {
  width: 26px;
  height: 26px;
}

/* Buttons */
.button-wrapper {
  display: flex;
  gap: 1rem;
}

/* Image section */
.case-study-image {
  width: 100%;
  text-align: center;
}

@media (min-width: 1280px) {
  .case-study-image {
    margin-right: 10rem;
  }
}

.dashboard-image {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

@media (min-width: 640px) {
  .dashboard-image {
    max-width: 40rem;
    max-height: auto;
  }
}

.price-comparison-container {
  text-align: center;
  background-color: black;
  color: white;
}

.background-section {
  /* background-repeat: no-repeat; */
  padding: 2.5rem 0 0 0;
  /* background-position: center;
  background-size: cover;
  background-image: url("../assets/images/line.png"); */
}

.text-section {
  margin-top: 1.75rem;
  padding: 0 4%;
}

.text-content-desc {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Inter' !important;
  font-weight: 500 !important;
  color: white;
}

@media (min-width: 640px) {

  /* sm */
  .text-section {
    padding: 0 16%;
    margin-top: 1.75rem;
  }

  .text-content-desc {
    font-size: 2rem;
    line-height: 2.3rem;
  }
}

@media (min-width: 768px) {

  /* md */
  .text-section {
    padding: 0 20%;
    margin-top: 2rem;
  }

  .text-content-desc {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {

  /* lg */
  .text-section {
    padding: 0 24%;
    margin-top: 2.5rem;
  }

  .text-content-desc {
    font-size: 36px;
    line-height: 44px;
  }
}

@media (min-width: 1536px) {

  /* 2xl */
  .text-section {
    padding: 0 32%;
    margin-top: 2.5rem;
  }

  .text-content-desc {
    font-size: 40px;
    line-height: 48px;
  }
}

.image-comparison-section {
  margin-top: 5rem;
  height: 20rem;
  position: relative;
}

.image-div {
  height: 20rem;
  background-color: black;
}

.priyaImage {
  width: 39vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.priyaImage:last-child {
  margin-left: -20px;
}

.image {
  width: 24rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 640px) {

  /* sm */
  .image {
    width: 15rem;
  }
}

@media (min-width: 768px) {

  /* md */
  .image {
    width: 20rem;
  }
}

@media (min-width: 1024px) {

  /* lg */
  .image {
    width: 24rem;
  }
}

@media (min-width: 1536px) {

  /* 2xl */
  .image {
    width: calc(100% / 3);
  }
}



@media (max-width:1200px) {
  #workContent {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .image-container {
    padding-top: 35px;
    padding-bottom: 0px;
    justify-content: center;
  }
}



@media (min-width: 1520px) {
  .price-comparison-container {
    padding-bottom: 4vw !important;
  }
}
@media (min-width: 1800px) {
  .price-comparison-container {
    padding-bottom: 6vw !important;
  }
}
@media (min-width: 2200px) {
  .price-comparison-container {
    padding-bottom: 9vw !important;
  }
}
@media (min-width: 2800px) {
  .price-comparison-container {
    padding-bottom: 13vw !important;
  }
}
@media (min-width: 4350px) {
  .price-comparison-container {
    padding-bottom: 17vw !important;
  }
}