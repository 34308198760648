/* PredictiveAnalysis.css */
.predicitive-container {
  display: flex;
  flex-direction: column;
  background-color: white;

}

.anchorTag {
  color: inherit !important;
}


@media (min-width: 1024px) {
  .predicitive-container {
    /* margin-top: 72px; */
    flex-direction: row;
  }
}


.predictive-title {
  font-size: 16px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.header img {
  height: 40px;
  width: 40px;
}

.header div {
  color: #7c7c7c;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #6b7280;
}

@media (min-width: 768px) {
  .header div {
    font-size: 20px;
  }
}

.predictive-description {
  margin-bottom: 20px;
  width: 98%;
  box-sizing: border-box;
  padding: 0 12px;
  text-align: start;
  font-weight: 500;
  color: black;
  font-size: 20px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .predictive-description {
    font-size: 24px;
    line-height: 30px;
    color: black;
  }
}

@media (min-width: 1240px) {
  .predictive-description {
    width: 70%;
    font-size: 32px;
    line-height: 42px;
    color: black;
  }
}

@media (min-width: 1536px) {
  .predictive-description {
    width: 75%;
    font-size: 40px;
    line-height: 50px;
    color: black;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

@media (min-width: 640px) {
  .button-container {
    flex-direction: row;
    gap: 8px;
    margin-top: 0;
  }
}

.image-container img {
  /* display: block;
  max-width: 100%;
  height: auto; */
}



#predictiveAnalysis {
  padding-top: 180px !important;
}

@media (max-width:1100px) {
  #predictiveAnalysis {
    padding-top: 13% !important;
  }
}
@media (max-width:995px) {
  #predictiveAnalysis {
    padding-top: 15% !important;
    padding-bottom: 50px;
  }
}


@media (max-width:768px) {
  #get-started-btn {
    font-size: 11px !important;
    padding: 10px 16px !important;

  }

  .predictiveBtnImg {
    overflow: hidden;
  }

  .header img {
    height: 30px;
    width: 30px;
  }

  .predictive-title {
    font-size: 16px !important;
  }
  #predictiveAnalysis {
    padding-top: 130px !important;
  }
}

@media (max-width:660px) {
  #predictiveAnalysis {
    padding-top: 100px !important;
  }
}
/* @media (max-width:630px) {
  #predictiveAnalysis {
    padding-top: 100px !important;
  }
} */

@media (max-width:600px) {
  #predictiveAnalysis {
    padding-top: 100px !important;
    padding-bottom: 30px;
  }
}

@media (max-width:500px) {
  #predictiveAnalysis {
    padding-top: 120px !important;
  }
}

@media (max-width:495px) {
  #predictiveAnalysis {
    padding-top: 80px !important;
  }
}
@media (max-width:470px) {
  #predictiveAnalysis {
    padding-top: 70px !important;
  }
}
@media (max-width:450px) {
  #predictiveAnalysis {
    padding-top: 70px !important;
  }
}

@media (max-width:430px) {
  #predictiveAnalysis {
    padding-top: 60px !important;
  }
}
@media (max-width:400px) {
  #predictiveAnalysis {
    padding-top: 80px !important;
  }
}

@media (max-width:350px) {
  #predictiveAnalysis {
    padding-top: 50px !important;
  }
}
@media (max-width:330px) {
  #predictiveAnalysis {
    padding-top: 50px !important;
  }
}


@media (min-width: 1520px) {
  #predictiveAnalysis {
    padding-top: 8.5vw !important;
  }
}
@media (min-width: 1800px) {
  #predictiveAnalysis {
    padding-top: 9vw !important;
  }
}
@media (min-width: 1900px) {
  #predictiveAnalysis {
    padding-top: 11vw !important;
  }
}
@media (min-width: 2200px) {
  #predictiveAnalysis {
    padding-top: 10vw !important;
  }
}
@media (min-width: 2800px) {
  #predictiveAnalysis {
    padding-top: 9vw !important;
  }
}
@media (min-width: 4350px) {
  #predictiveAnalysis {
    padding-top: 7vw !important;
  }
}