/* PredictiveAnalysis.css */
.autopilot-container {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.anchorTag {
  color: inherit !important;
}

@media (min-width: 640px) {
  .predicitive-container {
    padding-top: 1%;
  }
}

@media (max-width: 995px) {
  .autoPilotContent {
    padding-top: 50px;
  }
}


@media (max-width: 768px) {
  .predicitive-container {
    /* padding-top: 60px; */
  }

  #get-started-btn {
    font-size: 11px !important;
    padding: 10px 16px !important;
  }

  .autoPilot-title {
    font-size: 16px !important;
  }

  .autoPilot-header img {
    height: 20px !important;
    width: 18px !important;
  }

  .autopilot-container {
    padding: 0 20px !important;
    padding-bottom: 60px !important;
  }
}

@media (max-width: 600px) {
  .autoPilotContent {
    padding-top: 30px;
  }
}

@media (min-width: 1024px) {
  .predicitive-container {
    /* margin-top: 72px; */
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .predicitive-container {
    padding-top: 60px;
  }
}

.predictive-title {
  font-size: 18px;
}

.autoPilot-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.autoPilot-header img {
  height: 27px;
  width: 22px;
}

.autoPilot-header div {
  color: #7c7c7c;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #6b7280;
}

@media (max-width: 1024px) {
  .autopilot-container {
    padding-left: 48px !important;
  }
}

@media (max-width: 768px) {
  .autopilot-container {
    padding-left: 15px !important;
  }

  .autopilot-description {
    width: 100%;
  }
}



.autopilot-description {
  margin-bottom: 0.5rem;
  padding-left: 0.75rem;
  /* width: 80%; */
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: black;
}

@media (min-width: 768px) {
  .autopilot-description {
    font-size: 24px;
    line-height: 30px;
  }

  .autoPilot-header div {
    font-size: 20px;
  }
}

@media (min-width: 1240px) {
  .autopilot-description {
    width: 70%;
    font-size: 32px;
    line-height: 42px;
    color: black;
  }
}

@media (min-width: 1536px) {
  .autopilot-description {
    width: 75%;
    font-size: 40px;
    line-height: 50px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

@media (min-width: 640px) {
  .button-container {
    flex-direction: row;
    gap: 8px;
    margin-top: 0;
  }
}

.image-container img {
  /* display: block;
    max-width: 100%;
    height: auto; */
}